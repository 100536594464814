export const ShortCuts = {
  DELETE: "Delete Layer",
  ENTER: "Complete Drawing",
  1: "Toggle Car Mask Guide",
  2: "Toggle Wireframe Guide",
  3: "Toggle Sponsor Block Guide",
  4: "Toggle Number Block Guide",
  5: "Toggle Grid Guide",
  T: "Insert Text",
  S: "Insert Graphics",
  L: "Insert Logo",
  B: "Insert Base Paint",
  H: "Hide Transformer",
  P: "Sim Preview",
  "↑": "Move Layer Up",
  "↓": "Move Layer Down",
  "←": "Move Layer Left",
  "→": "Move Layer Right",
  "Shift ↑": "10x Move Layer Up",
  "Shift ↓": "10x Move Layer Down",
  "Shift ←": "10x Move Layer Left",
  "Shift →": "10x Move Layer Right",
  "Shift +": "Zoom In",
  "Shift -": "Zoom Out",
  "Shift 9": "Zoom To Fit",
  "Shift 0": "Zoom To 100%",
  "Shift D": "Default Mouse Mode",
  "Shift B": "Brush Drawing Mode",
  "Shift R": "Rectangle Drawing Mode",
  "Shift C": "Circle Drawing Mode",
  "Shift E": "Ellipse Drawing Mode",
  "Shift S": "Star Drawing Mode",
  "Shift G": "Ring Drawing Mode",
  "Shift O": "Regular-Polygon Drawing Mode",
  "Shift W": "Wedge Drawing Mode",
  "Shift A": "Arc Drawing Mode",
  "Shift P": "Polygon Drawing Mode",
  "Shift L": "Line Drawing Mode",
  "Shift >": "Arrow Drawing Mode",
  "Ctrl/Meta C": "Copy Layer To Clipboard",
  "Ctrl/Meta V": "Paste Layer From Clipboard",
  "Ctrl/Meta Z": "Undo Action",
  "Ctrl/Meta Y": "Redo Action",
  "Ctrl/Meta J": "Clone Layer",
  "Ctrl/Meta [": "Move Layer Order Lower",
  "Ctrl/Meta ]": "Move Layer Order Up",
  "Alt/Option ←": "Rotate Canvas Left",
  "Alt/Option →": "Rotate Canvas Right",
  "Alt/Option + Mouse Drag": "Clone & Move Layer",
};
